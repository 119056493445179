import React, { useState, useEffect } from 'react';
import './LandingPage.scss';
import { useNavigate } from 'react-router-dom';
import TopIcons from './TopIcons';

const LandingPage = () => {
    const [query, setQuery] = useState('');
    const navigate = useNavigate();
    const base = process.env.REACT_APP_API_BASE_URL;
    const local = process.env.REACT_APP_LOCAL_URL;


    // Carousel state and words
    const words = ['Shoes', 'Jackets', 'Hats', 'Pants', 'Jewelry', 'Trends', 'Beauty'];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 2000); // Change word every 2 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, [words.length]);

    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSearch = async () => {
        try {
            const response = await fetch(`${base}/api/search/query`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query }),
            });

            // Log the raw response for debugging
            console.log('Raw response:', response);

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const data = await response.json();
            console.log('API Response:', data); // This line was already present

            // More detailed logging
            if (!data) {
                console.error('Response data is null or undefined');
            } else if (typeof data !== 'object') {
                console.error('Response data is not an object:', typeof data);
            } else if (!data.results) {
                console.error('Response data does not contain a "results" property:', Object.keys(data));
            } else if (!Array.isArray(data.results)) {
                console.error('Results is not an array:', typeof data.results);
            } else if (data.results.length === 0) {
                console.log('Results array is empty');
            }
            
            // Check if results exist and is an array
            if (!data.results || !Array.isArray(data.results)) {
                console.error('No results found or results is not an array');
                return; // Exit the function if results are not valid
            }

            let resultsProducts = data.results;

            // Process the price for each product
            resultsProducts = resultsProducts.map(product => ({
                ...product,
                price: parseFloat(product.price.replace('$', '')) // Ensure price is a number
            }));

            console.log(resultsProducts);
            // Navigate to the results page with the query and processed search results
            navigate('/results', { state: { query, resultsProducts } });
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className="landing-page">
            <TopIcons />

            {/* Main Content */}
            <div className="content-wrapper">
                <h1 className="heading">
                    Swipe for <span className="rotating-word">{words[currentWordIndex]}</span>
                </h1>
                <div className="search-box-wrapper">
                    <input
                        type="text"
                        className="search-box"
                        placeholder="Search for products"
                        value={query}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                    <div className="arrow-icon" onClick={handleSearch}>
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 2L10.59 3.41L18.17 11H2V13H18.17L10.59 20.59L12 22L22 12L12 2Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
