import React, { useState, useEffect } from "react";
import './ProfilePage.scss';
import TopIcons from "./TopIcons";
import { useAuth } from './AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FiLogOut, FiSettings, FiEdit2, FiImage, FiTrash2, FiHeart, FiMessageSquare, FiX } from 'react-icons/fi'; // Add FiX
import FullScreenPost from './FullScreenPost';
import FollowListModal from './FollowListModal'; // Add this import

const ProfilePage = () => {
    const [profile, setProfile] = useState(null); // Initialize profile state
    const [posts, setPosts] = useState([]); // Keep this state for actual posts
    const [newPost, setNewPost] = useState({ 
        content: '', 
        media: [],
        links: [{ description: '', url: '' }] // Initialize with one empty link
    }); // Keep this for new post creation
    const [showNewPostForm, setShowNewPostForm] = useState(false); // Keep this for showing/hiding the new post form
    const { logout, userId: authUserId } = useAuth(); // Use logout from AuthProvider
    const navigate = useNavigate(); // Initialize navigate
    const { userId } = useParams(); // Get userId from URL parameters
    const [username, setUsername] = useState(''); // State for username
    const [error, setError] = useState(''); // State for error messages
    const [followersCount, setFollowersCount] = useState(0); // State for followers count
    const [followingCount, setFollowingCount] = useState(0); // State for following count
    const base = process.env.REACT_APP_API_BASE_URL;
    const local = process.env.REACT_APP_LOCAL_URL;

    // Default profile picture and cover image URLs
    const defaultProfilePicture = "https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=";
    const defaultCoverImage = "https://43bb9d1c7f95c24a2aa8-58ec507a5ae9dad69c8938b724dd8981.ssl.cf1.rackcdn.com/CrazyCap_trainerset01BLACKPR.jpg"; // You can replace this with any default cover image URL

    const [showDropdown, setShowDropdown] = useState(false);

    const [mainProfilePicture, setMainProfilePicture] = useState(defaultProfilePicture);
    const [postProfilePicture, setPostProfilePicture] = useState(defaultProfilePicture);

    const handleSettingsClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleSettingsOptionClick = () => {
        setShowDropdown(false);
        setShowSettingsModal(true);
        setSettingsForm({
            name: profile.name || '',
            username: profile.username || '',
            email: profile.email || '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    // Add this function to fetch like and comment counts
    const fetchPostCounts = async (postId) => {
        try {
            const [likesResponse, commentsResponse] = await Promise.all([
                axios.get(`${base}/api/post/likes/${postId}`),
                axios.get(`${base}/api/post/comments/${postId}`)
            ]);
            return {
                likeCount: likesResponse.data.likeCount,
                commentCount: commentsResponse.data.length
            };
        } catch (error) {
            console.error('Error fetching post counts:', error);
            return { likeCount: 0, commentCount: 0 };
        }
    };

    // Update the fetchProfileAndPosts function
    const fetchProfileAndPosts = async () => {
        try {
            console.log('Fetching profile for userId:', userId);
            const profileResponse = await axios.get(`${base}/api/profile/${userId}`);
            setProfile(profileResponse.data);
            setUsername(profileResponse.data.username);
            
            // Fetch profile picture separately
            const profilePictureResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
            if (profilePictureResponse.data.signedUrl) {
                console.log('Setting profile picture to signed URL:', profilePictureResponse.data.signedUrl);
                setMainProfilePicture(profilePictureResponse.data.signedUrl);
                setPostProfilePicture(profilePictureResponse.data.signedUrl);
            } else {
                console.log('No profile picture URL, using default');
                setMainProfilePicture(defaultProfilePicture);
                setPostProfilePicture(defaultProfilePicture);
            }

            // Fetch actual posts
            const postsResponse = await axios.get(`${base}/api/profile/posts/${userId}`);
            if (Array.isArray(postsResponse.data)) {
                const postsWithCounts = await Promise.all(postsResponse.data.map(async (post) => {
                    const counts = await fetchPostCounts(post.id);
                    return { ...post, ...counts };
                }));
                setPosts(postsWithCounts);
            } else {
                console.error('Unexpected posts data format:', postsResponse.data);
                setPosts([]);
            }

            // Fetch followers and following counts
            const followersResponse = await axios.get(`${base}/api/follow/followers/${userId}`);
            const followingResponse = await axios.get(`${base}/api/follow/following/${userId}`);
            setFollowersCount(followersResponse.data.length);
            setFollowingCount(followingResponse.data.length);

            // Check if the authenticated user is following this profile
            if (authUserId && userId !== authUserId) {
                const isFollowingResponse = await axios.get(`${base}/api/follow/check-following`, {
                    params: { followerId: authUserId, followedId: userId }
                });
                setIsFollowing(isFollowingResponse.data.isFollowing);
            }
        } catch (error) {
            console.error('Error fetching profile and posts:', error);
            setMainProfilePicture(defaultProfilePicture);
            setPostProfilePicture(defaultProfilePicture);
            setPosts([]);
        }
    };

    useEffect(() => {
        fetchProfileAndPosts();
    }, [userId, authUserId, base]);

    const handleNewPostChange = (e) => {
        setNewPost({ ...newPost, content: e.target.value });
    };

    const handleFileChange = (e) => {
        setNewPost({ ...newPost, media: [...e.target.files] });
    };

    // Update the link handlers
    const handleLinkChange = (index, field, value) => {
        const updatedLinks = newPost.links.map((link, i) => {
            if (i === index) {
                return { ...link, [field]: value };
            }
            return link;
        });
        setNewPost({ ...newPost, links: updatedLinks });
    };

    const addLinkFields = () => {
        setNewPost({
            ...newPost,
            links: [...newPost.links, { description: '', url: '' }]
        });
    };

    const removeLinkFields = (index) => {
        setNewPost({
            ...newPost,
            links: newPost.links.filter((_, i) => i !== index)
        });
    };

    // Update the handlePostSubmit function
    const handlePostSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('content', newPost.content);
        
        // Add media files
        newPost.media.forEach(file => {
            formData.append('media', file);
        });
        
        // Add links - make sure to filter out empty links
        const validLinks = newPost.links.filter(link => link.description && link.url);
        formData.append('links', JSON.stringify(validLinks));

        try {
            const response = await axios.post(`${base}/api/profile/create-post`, formData);
            fetchProfileAndPosts();
            setNewPost({ content: '', media: [], links: [{ description: '', url: '' }] });
            setShowNewPostForm(false);
        } catch (error) {
            console.error('Error creating post:', error);
            setError('Failed to create post');
        }
    };

    const handleProfilePictureChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Check file size (5MB limit)
            if (file.size > 5 * 1024 * 1024) {
                setError('File size exceeds 5MB limit');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Data = reader.result.split(',')[1];
                const fileType = file.type;
                const fileName = file.name;

                try {
                    const response = await axios.post(`${base}/api/profile/upload-profile-picture`, {
                        userId: userId, // Make sure userId is defined and correct
                        image: {
                            filename: fileName,
                            content: base64Data,
                            contentType: fileType
                        }
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (response.data.signedUrl) {
                        setProfile(prevProfile => ({
                            ...prevProfile,
                            profile_picture_url: response.data.url
                        }));
                        setMainProfilePicture(response.data.signedUrl);
                        setPostProfilePicture(response.data.signedUrl);
                    } else {
                        setError('Failed to upload profile picture: No signed URL received');
                    }
                } catch (error) {
                    console.error('Error uploading profile picture:', error);
                    console.error('Error response:', error.response?.data);
                    setError('Failed to upload profile picture: ' + (error.response?.data?.message || error.message));
                }
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        const fetchProfilePicture = async () => {
            try {
                const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                if (response.data.signedUrl) {
                    setMainProfilePicture(response.data.signedUrl);
                    setPostProfilePicture(response.data.signedUrl);
                } else {
                    setMainProfilePicture(defaultProfilePicture);
                    setPostProfilePicture(defaultProfilePicture);
                }
            } catch (error) {
                console.error('Error fetching profile picture:', error);
                setMainProfilePicture(defaultProfilePicture);
                setPostProfilePicture(defaultProfilePicture);
            }
        };

        fetchProfilePicture();
    }, [userId, base]);

    useEffect(() => {
        const refreshSignedUrl = async () => {
            if (profile && profile.profile_picture_url) {
                try {
                    const signedUrlResponse = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
                    if (signedUrlResponse.data.signedUrl) {
                        setMainProfilePicture(signedUrlResponse.data.signedUrl);
                        setPostProfilePicture(signedUrlResponse.data.signedUrl);
                    }
                } catch (error) {
                    console.error('Error refreshing signed URL:', error);
                }
            }
        };

        // Refresh the signed URL every 50 minutes (3000000 milliseconds)
        const intervalId = setInterval(refreshSignedUrl, 3000000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [profile, userId, base]);

    const handleDeletePost = async (postId) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await axios.delete(`${base}/api/profile/delete-post/${postId}`, {
                    data: { userId: userId }
                });
                // Refresh posts after successful deletion
                fetchProfileAndPosts();
            } catch (error) {
                console.error('Error deleting post:', error);
                setError('Failed to delete post');
            }
        }
    };

    const [fullScreenPostIndex, setFullScreenPostIndex] = useState(null);
    const [showComments, setShowComments] = useState(false);

    const handlePostClick = (post) => {
        const index = posts.findIndex(p => p.id === post.id);
        setFullScreenPostIndex(index);
    };

    const handleFullScreenClose = () => {
        setFullScreenPostIndex(null);
        setShowComments(false);
        // Ensure any other state related to the full-screen view is reset
    };

    const handleFullScreenIndexChange = (newIndex) => {
        setFullScreenPostIndex(newIndex);
    };

    const handleCommentCountChange = (postId, newCount) => {
        setPosts(prevPosts => prevPosts.map(post => 
          post.id === postId 
            ? { ...post, comment_count: newCount } 
            : post
        ));
    };

    const handleLike = async (postId) => {
        if (!userId) {
            console.log('Please log in to like posts');
            return;
        }

        try {
            const response = await axios.post(`${base}/api/post/togglelike`, {
                userId: userId,
                postId
            });
            
            // Update posts state
            setPosts(prevPosts => prevPosts.map(post => 
                post.id === postId 
                    ? { 
                        ...post, 
                        userLiked: response.data.liked, 
                        likeCount: response.data.liked ? (post.likeCount || 0) + 1 : (post.likeCount || 1) - 1 
                    } 
                    : post
            ));
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        const fetchFollowData = async () => {
            try {
                const followersResponse = await axios.get(`${base}/api/follow/followers/${userId}`);
                const followingResponse = await axios.get(`${base}/api/follow/following/${userId}`);
                setFollowersCount(followersResponse.data.length);
                setFollowingCount(followingResponse.data.length);

                if (authUserId) {
                    const isFollowingResponse = await axios.get(`${base}/api/follow/following/${authUserId}`);
                    setIsFollowing(isFollowingResponse.data.some(user => user.id === userId));
                }
            } catch (error) {
                console.error('Error fetching follow data:', error);
            }
        };

        fetchFollowData();
    }, [userId, authUserId, base]);

    const handleFollowToggle = async () => {
        if (userId === authUserId) return; // Prevent following self

        try {
            if (isFollowing) {
                await axios.delete(`${base}/api/follow/unfollow`, { data: { followerId: authUserId, followedId: userId } });
                setFollowersCount(prev => prev - 1);
            } else {
                await axios.post(`${base}/api/follow/follow`, { followerId: authUserId, followedId: userId });
                setFollowersCount(prev => prev + 1);
            }
            setIsFollowing(!isFollowing);
        } catch (error) {
            console.error('Error toggling follow:', error);
        }
    };

    const isOwnProfile = authUserId === userId;

    const [showFollowModal, setShowFollowModal] = useState(false);
    const [followModalType, setFollowModalType] = useState('');
    const [followList, setFollowList] = useState([]);

    const handleFollowClick = async (type) => {
        setFollowModalType(type);
        try {
            const response = await axios.get(`${base}/api/follow/${type}/${userId}`);
            setFollowList(response.data);
            setShowFollowModal(true);
        } catch (error) {
            console.error(`Error fetching ${type} list:`, error);
        }
    };

    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [settingsForm, setSettingsForm] = useState({
        name: '',
        username: '',
        email: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [settingsMessage, setSettingsMessage] = useState({ type: '', content: '' });

    const handleSettingsChange = (e) => {
        setSettingsForm({ ...settingsForm, [e.target.name]: e.target.value });
    };

    const handleSettingsSubmit = async (e) => {
        e.preventDefault();
        setSettingsMessage({ type: '', content: '' });

        // Check if password is being changed
        const isChangingPassword = settingsForm.newPassword || settingsForm.confirmNewPassword;

        // Validate password change
        if (isChangingPassword) {
            if (!settingsForm.currentPassword) {
                setSettingsMessage({ type: 'error', content: 'Current password is required to change password.' });
                return;
            }
            if (settingsForm.newPassword !== settingsForm.confirmNewPassword) {
                setSettingsMessage({ type: 'error', content: 'New passwords do not match.' });
                return;
            }
        }

        try {
            const response = await axios.put(`${base}/api/profile/update`, {
                userId: userId,
                name: settingsForm.name,
                username: settingsForm.username,
                email: settingsForm.email,
                currentPassword: isChangingPassword ? settingsForm.currentPassword : undefined,
                newPassword: isChangingPassword ? settingsForm.newPassword : undefined
            });

            if (response.data.success) {
                setSettingsMessage({ type: 'success', content: 'Settings updated successfully!' });
                // Update local profile state
                setProfile(prevProfile => ({
                    ...prevProfile,
                    name: settingsForm.name,
                    username: settingsForm.username,
                    email: settingsForm.email
                }));
                // Clear password fields
                setSettingsForm(prev => ({
                    ...prev,
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                }));
            } else {
                setSettingsMessage({ type: 'error', content: response.data.message || 'Failed to update settings.' });
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            setSettingsMessage({ type: 'error', content: error.response?.data?.message || 'An error occurred while updating settings.' });
        }
    };

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('settings-modal-overlay')) {
            setShowSettingsModal(false);
        }
    };

    const [activeTab, setActiveTab] = useState('posts');
    const [savedPosts, setSavedPosts] = useState([]);

    const fetchSavedPosts = async () => {
        try {
            const response = await axios.get(`${base}/api/post/saved/user/${userId}`);
            setSavedPosts(response.data);
        } catch (error) {
            console.error('Error fetching saved posts:', error);
            setSavedPosts([]);
        }
    };

    useEffect(() => {
        if (activeTab === 'saved') {
            fetchSavedPosts();
        }
    }, [activeTab]);

    if (!profile) {
        return <div>Loading...</div>; // Show loading state while fetching profile
    }

    return (
        <div className="profile-page">
            <div className="cover-image">
                <img src={defaultCoverImage} alt="Cover" />
                <div className="top-icons-container">
                    <TopIcons />
                </div>
            </div>
            <div className="profile-header">
                <div className="profile-picture">
                    <img 
                        src={mainProfilePicture} 
                        alt="Profile" 
                        crossOrigin="anonymous"
                        key={mainProfilePicture}
                        onError={(e) => {
                            console.error('Error loading image:', e.target.src);
                            e.target.src = defaultProfilePicture;
                        }}
                    />
                    {isOwnProfile && (
                        <>
                            <input
                                type="file"
                                id="profile-picture-input"
                                accept="image/*"
                                onChange={handleProfilePictureChange}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="profile-picture-input" className="change-picture-button">
                                <FiEdit2 />
                            </label>
                        </>
                    )}
                </div>
                {isOwnProfile && (
                    <div className="settings-container">
                        <button className="settings-button" onClick={handleSettingsClick}>
                            <FiSettings />
                        </button>
                        {showDropdown && (
                            <div className="settings-dropdown">
                                <button onClick={handleSettingsOptionClick}>Settings</button>
                                <button onClick={handleLogout}>Logout</button>
                            </div>
                        )}
                    </div>
                )}
                <h1 className="name">{profile.name}</h1>
                <h2 className="username">@{profile.username}</h2>
                <div className="follow-stats">
                    <span onClick={() => handleFollowClick('followers')} className="clickable">
                        {followersCount} followers
                    </span>
                    <span> · </span>
                    <span onClick={() => handleFollowClick('following')} className="clickable">
                        {followingCount} following
                    </span>
                </div>
                {!isOwnProfile && (
                    <button onClick={handleFollowToggle} className="follow-button">
                        {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                )}
                {isOwnProfile && (
                    <button className="new-post-button" onClick={() => setShowNewPostForm(true)}>
                        New Post +
                    </button>
                )}
            </div>

            <div className="profile-tabs">
                <button 
                    className={`tab-button ${activeTab === 'posts' ? 'active' : ''}`}
                    onClick={() => setActiveTab('posts')}
                >
                    My Posts
                </button>
                <button 
                    className={`tab-button ${activeTab === 'saved' ? 'active' : ''}`}
                    onClick={() => setActiveTab('saved')}
                >
                    Saved
                </button>
            </div>

            <div className="user-posts">
                <div className="posts-grid">
                    {(activeTab === 'posts' ? posts : savedPosts).map((post, index) => (
                        <div key={post.id} className="post-card" onClick={() => handlePostClick(post)}>
                            {post.media && post.media.length > 0 && (
                                post.media[0].media_type === 'image' ? (
                                    <img 
                                        src={post.media[0].signedUrl} 
                                        alt={`Post ${post.id}`} 
                                        className="post-image"
                                    />
                                ) : (
                                    <video 
                                        src={post.media[0].signedUrl} 
                                        controls 
                                        className="post-image"
                                    />
                                )
                            )}
                            <div className="post-overlay">
                                {post.content && (
                                    <p className="post-caption">{post.content}</p>
                                )}
                                <div className="post-info">
                                    <div className="post-stats">
                                        <span className="post-stat">
                                            <FiHeart /> {post.likeCount}
                                        </span>
                                        <span className="post-stat">
                                            <FiMessageSquare /> {post.commentCount}
                                        </span>
                                    </div>
                                    {isOwnProfile && (
                                        <button 
                                            className="delete-post-button" 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeletePost(post.id);
                                            }}
                                        >
                                            <FiTrash2 />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showNewPostForm && (
                <div className="new-post-overlay">
                    <div className="new-post-form">
                        <h2>Create a New Post</h2>
                        <form onSubmit={handlePostSubmit}>
                            <label htmlFor="file-upload" className="custom-file-upload">
                                <FiImage /> Add Media
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                accept="image/*,video/*"
                                style={{ display: 'none' }}
                            />
                            {newPost.media.length > 0 && (
                                <p>{newPost.media.length} file(s) selected</p>
                            )}
                            <textarea
                                name="content"
                                value={newPost.content}
                                onChange={handleNewPostChange}
                                placeholder="Write your caption..."
                            />
                            
                            {/* Links section third */}
                            <div className="links-section">
                                <h3>Add Links</h3>
                                <div className="links-container">
                                    {newPost.links.map((link, index) => (
                                        <div key={index} className="link-row">
                                            <div className="link-inputs">
                                                <input
                                                    type="text"
                                                    value={link.description}
                                                    onChange={(e) => handleLinkChange(index, 'description', e.target.value)}
                                                    placeholder="Description (e.g., Green Sweater)"
                                                />
                                                <input
                                                    type="url"
                                                    value={link.url}
                                                    onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                                                    placeholder="URL"
                                                />
                                                <button 
                                                    type="button"
                                                    onClick={() => removeLinkFields(index)}
                                                    className="remove-link-button"
                                                >
                                                    <FiX />
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button 
                                        type="button" 
                                        onClick={addLinkFields}
                                        className="add-link-button"
                                    >
                                        Add Link
                                    </button>
                                </div>
                            </div>

                            <div className="form-buttons">
                                <button type="button" className="cancel-button" onClick={() => setShowNewPostForm(false)}>Cancel</button>
                                <button type="submit" className="post-button">Post</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {fullScreenPostIndex !== null && (
                <FullScreenPost
                    posts={posts.map(post => ({
                        ...post,
                        username: profile.username // Add the username to each post
                    }))}
                    initialPostIndex={fullScreenPostIndex}
                    onClose={handleFullScreenClose}
                    onLike={handleLike}
                    userId={userId}
                    onCommentCountChange={handleCommentCountChange}
                    initialShowComments={showComments}
                    onIndexChange={handleFullScreenIndexChange}
                />
            )}

            {showFollowModal && (
                <FollowListModal
                    type={followModalType}
                    list={followList}
                    onClose={() => setShowFollowModal(false)}
                />
            )}

            {showSettingsModal && (
                <div className="settings-modal-overlay" onClick={handleClickOutside}>
                    <div className="settings-modal">
                        <button className="close-modal" onClick={() => setShowSettingsModal(false)}>
                            <FiX />
                        </button>
                        <h2>Account Settings</h2>
                        <form onSubmit={handleSettingsSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={settingsForm.name}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={settingsForm.username}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={settingsForm.email}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="currentPassword">Current Password</label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    name="currentPassword"
                                    value={settingsForm.currentPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="newPassword">New Password</label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={settingsForm.newPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmNewPassword">Confirm New Password</label>
                                <input
                                    type="password"
                                    id="confirmNewPassword"
                                    name="confirmNewPassword"
                                    value={settingsForm.confirmNewPassword}
                                    onChange={handleSettingsChange}
                                />
                            </div>
                            {settingsMessage.content && (
                                <div className={`settings-message ${settingsMessage.type}`}>
                                    {settingsMessage.content}
                                </div>
                            )}
                            <button type="submit" className="save-settings-button">Save Changes</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePage;
