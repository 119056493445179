import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaComment, FaHeart, FaRegHeart, FaTimes, FaLink, FaBookmark, FaRegBookmark } from 'react-icons/fa';
import CommentSection from './CommentSection';
import './FullScreenPost.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LikesList from './LikesList'; // We'll create this component
import LinksList from './LinksList';

const FullScreenPost = ({ posts, initialPostIndex, onClose, onLike, userId, onCommentCountChange, initialShowComments = false, onIndexChange }) => {
  const [showComments, setShowComments] = useState(initialShowComments);
  const [currentIndex, setCurrentIndex] = useState(initialPostIndex);
  const currentPost = posts[currentIndex];
  const touchStartY = useRef(null);
  const lastScrollTime = useRef(0);
  const scrollCooldown = 300; // ms cooldown between scrolls
  const isMobile = window.innerWidth <= 768; // Define mobile breakpoint
  const [showFullCaption, setShowFullCaption] = useState(false);
  const [userProfilePictures, setUserProfilePictures] = useState({});
  const local = process.env.REACT_APP_LOCAL_URL;
  const base = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [showLikes, setShowLikes] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [postLinks, setPostLinks] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    // Disable body scrolling when component mounts
    document.body.style.overflow = 'hidden';
    // Re-enable body scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    fetchUserProfilePictures();
  }, [posts]);

  const fetchUserProfilePictures = async () => {
    const uniqueUserIds = [...new Set(posts.map(post => post.user_id))];
    const profilePictures = {};

    for (const userId of uniqueUserIds) {
      try {
        const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
        profilePictures[userId] = response.data.signedUrl;
      } catch (error) {
        console.error('Error fetching user profile picture:', error);
        profilePictures[userId] = null;
      }
    }

    setUserProfilePictures(profilePictures);
  };

  const handleLike = () => {
    const currentPost = posts[currentIndex];
    if (currentPost.user_id === userId) {
      // If it's the user's own post, show likes list
      setShowLikes(!showLikes);
      setShowComments(false); // Close comments if open
      setShowLinks(false);
      console.log('showLinks:', showLinks);
    } else {
      // For other posts, like/unlike as before
      onLike(currentPost.id);
    }
  };

  const toggleComments = (e) => {
    e.stopPropagation();
    setShowComments(!showComments);
    setShowLikes(false); // Close likes list if open
    setShowLinks(false);
    console.log('showLinks:', showLinks);
  };

  const closeComments = () => {
    setShowComments(false);
  };

  const closeLikes = () => {
    setShowLikes(false);
  };

  const closeLinks = () => {
    setShowLinks(false);
  };

  const handleScroll = (direction) => {
    if (isMobile && showComments) return; // Prevent scrolling only on mobile when comments are open

    const now = Date.now();
    if (now - lastScrollTime.current < scrollCooldown) {
      return; // Ignore scroll if we're still in cooldown
    }
    
    let newIndex = currentIndex;
    if (direction === 'up' && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (direction === 'down' && currentIndex < posts.length - 1) {
      newIndex = currentIndex + 1;
    }
    
    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
      onIndexChange(newIndex); // Notify parent component of index change
    }
    
    lastScrollTime.current = now;
  };

  const handleWheel = (e) => {
    if (isMobile && showComments) return; // Prevent scrolling only on mobile when comments are open

    e.preventDefault();
    if (Math.abs(e.deltaY) > 50) { // Increased threshold for wheel scrolling
      if (e.deltaY > 0) {
        handleScroll('down');
      } else {
        handleScroll('up');
      }
    }
  };

  const handleTouchStart = (e) => {
    if (isMobile && showComments) return; // Prevent touch handling only on mobile when comments are open
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if ((isMobile && showComments) || touchStartY.current === null) return; // Prevent touch handling only on mobile when comments are open

    const touchEndY = e.touches[0].clientY;
    const deltaY = touchEndY - touchStartY.current;

    if (Math.abs(deltaY) > 100) { // Increased threshold for touch scrolling
      if (deltaY > 0) {
        handleScroll('up');
      } else {
        handleScroll('down');
      }
      touchStartY.current = null;
    }
  };

  const handleTouchEnd = () => {
    touchStartY.current = null;
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isMobile && showComments) return; // Prevent key handling only on mobile when comments are open

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        handleScroll('up');
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        handleScroll('down');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, showComments, isMobile]);

  const handleCloseFullScreen = (e) => {
    e.stopPropagation();
    onClose();
    setShowComments(false); // Close comments
    setShowLikes(false); // Close likes list
  };

  const toggleCaption = (e) => {
    e.stopPropagation();
    setShowFullCaption(!showFullCaption);
  };

  const handleShareLink = (e) => {
    e.stopPropagation();
    setShowLinks(!showLinks);
    setShowComments(false);
    setShowLikes(false);
  };

  const fetchUserProfilePicture = useCallback(async (userId) => {
    if (userProfilePictures[userId]) return userProfilePictures[userId];

    try {
      const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
      const newProfilePictures = { ...userProfilePictures, [userId]: response.data.signedUrl };
      setUserProfilePictures(newProfilePictures);
      return response.data.signedUrl;
    } catch (error) {
      console.error('Error fetching user profile picture:', error);
      return null;
    }
  }, [base, userProfilePictures]);

  useEffect(() => {
    const fetchProfilePictures = async () => {
      const currentPost = posts[currentIndex];
      const prevPost = posts[currentIndex - 1];
      const nextPost = posts[currentIndex + 1];

      const postsToFetch = [currentPost, prevPost, nextPost].filter(Boolean);
      const uniqueUserIds = [...new Set(postsToFetch.map(post => post.user_id))];

      for (const userId of uniqueUserIds) {
        if (!userProfilePictures[userId]) {
          await fetchUserProfilePicture(userId);
        }
      }
    };

    fetchProfilePictures();
  }, [currentIndex, posts, fetchUserProfilePicture, userProfilePictures]);

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  // Add this new useEffect hook
  useEffect(() => {
    console.log('showLikes:', showLikes);
  }, [showLikes]);

  const handleLikesListClose = (shouldCloseFullScreen = false) => {
    setShowLikes(false);
    if (shouldCloseFullScreen) {
      onClose();
    }
  };

  useEffect(() => {
    const fetchLinks = async () => {
      if (!currentPost?.id) return;
      
      try {
        const response = await axios.get(`${base}/api/post/links/${currentPost.id}`);
        setPostLinks(response.data);
      } catch (error) {
        console.error('Error fetching links:', error);
        setPostLinks([]);
      }
    };

    fetchLinks();
  }, [currentPost?.id, base]);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (!currentPost?.id) return;
      
      try {
        const response = await axios.get(`${base}/api/post/saved/${currentPost.id}?userId=${userId}`);
        setIsSaved(response.data.saved);
      } catch (error) {
        console.error('Error fetching saved status:', error);
        setIsSaved(false);
      }
    };

    fetchSavedStatus();
  }, [currentPost?.id, userId, base]);

  const handleSave = async (e) => {
    e.stopPropagation();
    try {
      const response = await axios.post(`${base}/api/post/togglesave`, {
        userId,
        postId: currentPost.id
      });
      setIsSaved(response.data.saved);
    } catch (error) {
      console.error('Error toggling save:', error);
    }
  };

  return (
    <div 
      className="full-screen-post-overlay" 
      onClick={onClose}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div 
        className="full-screen-post-content" 
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-button" onClick={handleCloseFullScreen}>
          <FaTimes />
        </button>
        <div className={`post-display ${(showComments || showLikes || showLinks) ? 'with-sidebar' : ''}`}>
          <div className="media-container">
            {currentPost.media && currentPost.media.length > 0 && currentPost.media.map((media, index) => (
              <div key={index} className="media-item">
                {media.media_type === 'image' ? (
                  <img 
                    src={media.signedUrl} 
                    alt={`Post ${currentPost.id} - Image ${index + 1}`} 
                    className="post-image"
                  />
                ) : (
                  <video 
                    src={media.signedUrl} 
                    controls 
                    className="post-video"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="post-overlay">
            <div className="post-info">
              <div className="post-user-info">
                <img 
                  src={userProfilePictures[currentPost.user_id]} 
                  className="post-user-avatar" 
                  alt="User avatar"
                />
                <span 
                  className="post-username"
                  onClick={() => handleUserClick(currentPost.user_id)}
                >
                  {currentPost.username || 'Unknown User'}
                </span>
              </div>
              {currentPost.content && (
                <div className="post-caption-container">
                  <p className="post-caption" style={{ WebkitLineClamp: showFullCaption ? 'unset' : '2' }}>
                    {currentPost.content}
                  </p>
                  {currentPost.content.length > 70 && (
                    <button className="show-more-button" onClick={toggleCaption}>
                      {showFullCaption ? 'Show less' : 'Show more'}
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="post-actions">
              <button className="like-button" onClick={handleLike}>
                <span>{currentPost.likeCount || 0}</span>
                {currentPost.user_id === userId ? (
                  <FaHeart />
                ) : (
                  currentPost.userLiked ? <FaHeart color="red" /> : <FaRegHeart />
                )}
              </button>
              <button className="comment-button" onClick={toggleComments}>
                <span>{currentPost.comment_count || 0}</span>
                <FaComment />
              </button>
              <button className="link-button" onClick={handleShareLink}>
                <FaLink />
              </button>
              <button className="save-button" onClick={handleSave}>
                {isSaved ? <FaBookmark style={{ color: '#FFD700' }} /> : <FaRegBookmark />}
              </button>
            </div>
          </div>
        </div>
        <CommentSection
          post={currentPost}
          userId={userId}
          onCommentCountChange={onCommentCountChange}
          onClose={closeComments}
          isFullScreen={true}
          show={showComments}
        />
        {currentPost.user_id === userId && (
        <div className="likes-list-container">
          <LikesList
            postId={currentPost.id}
            onClose={closeLikes}
            onCloseAndNavigate={handleLikesListClose}
            isFullScreen={true}
            show={showLikes}
          />
        </div>
        )}
        <LinksList
          links={postLinks}
          onClose={closeLinks}
          show={showLinks}
        />
      </div>
    </div>
  );
};

export default FullScreenPost;
