import React, { useState, useEffect, useRef, useCallback } from 'react';
import TopIcons from './TopIcons';
import axios from 'axios';
import { FaHeart, FaRegHeart, FaComment, FaSearch, FaHistory, FaStar } from 'react-icons/fa';
import { useAuth } from './AuthProvider';
import './DiscoverPage.scss';
import CommentSection from './CommentSection';
import FullScreenPost from './FullScreenPost';
import { useNavigate, Link } from 'react-router-dom';

const DiscoverPage = () => {
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState('discover');
  const local = process.env.REACT_APP_LOCAL_URL;
  const base = process.env.REACT_APP_API_BASE_URL;
  const { user, isAuthenticated, userId } = useAuth();
  const [fullScreenPostIndex, setFullScreenPostIndex] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const postsGridRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [topUsers, setTopUsers] = useState([]);
  const navigate = useNavigate();
  const [userProfilePictures, setUserProfilePictures] = useState({});
  const [followingPosts, setFollowingPosts] = useState([]);

  useEffect(() => {
    console.log('Auth state:', { isAuthenticated, userId, user });
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${base}/api/discover/posts`, {
          params: { userId: userId }
        });
        if (isAuthenticated && userId) {
          console.log('Fetching posts with likes for user:', userId);
          const postsWithLikes = await Promise.all(response.data.map(async (post) => {
            const likeInfo = await axios.get(`${base}/api/post/likes/${post.id}`, {
              params: { userId: userId }
            });
            return { ...post, ...likeInfo.data };
          }));
          setPosts(postsWithLikes);
        } else {
          console.log('Fetching posts without likes');
          setPosts(response.data.map(post => ({ ...post, likeCount: 0, userLiked: false })));
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();

    // Fetch following posts
    const fetchFollowingPosts = async () => {
      if (isAuthenticated && userId) {
        try {
          console.log('Fetching following posts for user:', userId);
          const response = await axios.get(`${base}/api/discover/following-posts/${userId}`);
          console.log('Following posts response:', response.data);
          
          if (response.data.length === 0) {
            console.log('No following posts received');
            setFollowingPosts([]);
          } else {
            const postsWithLikes = await Promise.all(response.data.map(async (post) => {
              const likeInfo = await axios.get(`${base}/api/post/likes/${post.id}`, {
                params: { userId: userId }
              });
              return { ...post, ...likeInfo.data };
            }));
            console.log('Following posts with likes:', postsWithLikes);
            setFollowingPosts(postsWithLikes);
          }
        } catch (error) {
          console.error('Error fetching following posts:', error);
        }
      }
    };

    fetchFollowingPosts();
  }, [base, isAuthenticated, userId]);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        console.log('Fetching top users from:', `${base}/api/follow/top-users`);
        const response = await axios.get(`${base}/api/follow/top-users`);
        console.log('Top users API response:', response);
        if (response.data && Array.isArray(response.data)) {
          console.log('Setting top users:', response.data);
          setTopUsers(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching top users:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      }
    };

    fetchTopUsers();
    // Load recent searches from localStorage
    const savedRecentSearches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
    setRecentSearches(savedRecentSearches);
  }, [base]);

  const handleLike = async (postId, event) => {
    // Check if event exists before calling stopPropagation
    if (event && typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }

    if (!isAuthenticated || !userId) {
      console.log('Please log in to like posts');
      return;
    }

    const updatePostsState = (prevPosts) => prevPosts.map(post => 
      post.id === postId 
        ? { 
            ...post, 
            userLiked: !post.userLiked, 
            likeCount: post.userLiked ? post.likeCount - 1 : post.likeCount + 1 
          } 
        : post
    );

    // Optimistically update UI
    setPosts(updatePostsState);
    setFollowingPosts(updatePostsState);

    try {
      const response = await axios.post(`${base}/api/post/togglelike`, {
        userId: userId,
        postId
      });
      
      // If the server response doesn't match our optimistic update, revert
      if (response.data.liked !== updatePostsState(posts).find(p => p.id === postId).userLiked) {
        setPosts(prevPosts => updatePostsState(prevPosts));
        setFollowingPosts(prevPosts => updatePostsState(prevPosts));
      }

    } catch (error) {
      console.error('Error toggling like:', error);
      // Revert optimistic update on error
      setPosts(prevPosts => updatePostsState(prevPosts));
      setFollowingPosts(prevPosts => updatePostsState(prevPosts));
    }
  };

  const handleCommentClick = (post, event) => {
    event.stopPropagation();
    console.log('Comment clicked for post:', post.id);
    setFullScreenPostIndex(posts.findIndex(p => p.id === post.id));
    setShowComments(true);
  };

  const handlePostClick = (post) => {
    const index = posts.findIndex(p => p.id === post.id);
    setFullScreenPostIndex(index);
  };

  const handleFullScreenClose = () => {
    if (fullScreenPostIndex !== null && postsGridRef.current) {
      const postElements = postsGridRef.current.children;
      if (postElements[fullScreenPostIndex]) {
        postElements[fullScreenPostIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    setFullScreenPostIndex(null);
    setShowComments(false);
  };

  const handleFullScreenIndexChange = (newIndex) => {
    setFullScreenPostIndex(newIndex);
  };

  const handleCommentCountChange = (postId, newCount) => {
    setPosts(prevPosts => prevPosts.map(post => 
      post.id === postId 
        ? { ...post, comment_count: newCount } 
        : post
    ));
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;

    try {
      console.log('Searching for:', searchTerm);
      const response = await axios.get(`${base}/api/follow/search`, {
        params: { query: searchTerm }
      });
      console.log('Search results:', response.data);
      setSearchResults(response.data.map(user => ({
        ...user,
        profile_picture_url: user.signedUrl 
      })));

      // Add to recent searches
      const updatedRecentSearches = [
        { id: Date.now(), username: searchTerm },
        ...recentSearches.filter(search => search.username !== searchTerm)
      ].slice(0, 5);
      setRecentSearches(updatedRecentSearches);
      localStorage.setItem('recentSearches', JSON.stringify(updatedRecentSearches));
    } catch (error) {
      console.error('Error searching users:', error);
    }
  };

  const handleSearchInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim()) {
      try {
        const response = await axios.get(`${base}/api/follow/search`, {
          params: { query: value }
        });
        setSearchResults(response.data.map(user => ({
          ...user,
          profile_picture_url: user.signedUrl 
        })));
      } catch (error) {
        console.error('Error searching users:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const fetchUserProfilePicture = useCallback(async (userId) => {
    if (userProfilePictures[userId]) return userProfilePictures[userId];

    try {
      const response = await axios.get(`${base}/api/profile/profile-picture/${userId}`);
      const newProfilePictures = { ...userProfilePictures, [userId]: response.data.signedUrl };
      setUserProfilePictures(newProfilePictures);
      return response.data.signedUrl;
    } catch (error) {
      console.error('Error fetching user profile picture:', error);
      return null;
    }
  }, [base, userProfilePictures]);

  useEffect(() => {
    const fetchProfilePictures = async () => {
      const uniqueUserIds = [...new Set(posts.map(post => post.user_id))];
      for (const userId of uniqueUserIds) {
        if (!userProfilePictures[userId]) {
          await fetchUserProfilePicture(userId);
        }
      }
    };

    fetchProfilePictures();
  }, [posts, fetchUserProfilePicture, userProfilePictures]);

  const renderPosts = () => {
    console.log('Rendering posts. Active tab:', activeTab);
    console.log('Posts:', posts);
    console.log('Following posts:', followingPosts);

    const currentPosts = activeTab === 'discover' ? posts : followingPosts;
    
    console.log('Current posts to render:', currentPosts);

    if (activeTab === 'following' && !isAuthenticated) {
      console.log('User not authenticated, showing login message');
      return (
        <div className="login-message">
          <Link to="/login">Login</Link> to follow creators
        </div>
      );
    }

    if (activeTab === 'following' && followingPosts.length === 0) {
      console.log('No following posts, showing message');
      return (
        <div className="no-posts-message">
          You're not following anyone yet or they haven't posted anything.
        </div>
      );
    }

    return (
      <div className="posts-grid" ref={postsGridRef}>
        {currentPosts.map((post, index) => (
          <div key={post.id} className="post-card" onClick={() => handlePostClick(post)}>
            {post.media && post.media.length > 0 && (
              post.media[0].media_type === 'image' ? (
                <img 
                  src={post.media[0].signedUrl} 
                  alt={`Post ${post.id}`} 
                  className="post-image"
                />
              ) : (
                <video 
                  src={post.media[0].signedUrl} 
                  controls 
                  className="post-image"
                />
              )
            )}
            <div className="post-overlay">
              <div className="post-info">
                <div className="post-user-info">
                  <img 
                    src={userProfilePictures[post.user_id] || 'path/to/default/avatar.png'} 
                    alt={post.username}
                    className="post-user-avatar"
                  />
                  <span className="post-username">@{post.username}</span>
                </div>
                <div className="post-actions">
                  <button className="like-button" onClick={(e) => handleLike(post.id, e)}>
                    <span>{post.likeCount || 0}</span>
                    {post.userLiked ? <FaHeart color="red" /> : <FaRegHeart />}
                  </button>
                  <button className="comment-button" onClick={(e) => handleCommentClick(post, e)}>
                    <span>{post.comment_count || 0}</span>
                    <FaComment />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="discover-page">
      <TopIcons />
      <div className="content-wrapper">
        <div className="search-container">
          <form onSubmit={handleSearch} className="search-form">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setTimeout(() => setSearchFocused(false), 200)}
              placeholder="Search users..."
            />
            <button type="submit"><FaSearch /></button>
          </form>
          {searchFocused && (
            <div className="search-dropdown">
              {!searchTerm && (
                <>
                  {recentSearches.length > 0 && (
                    <div className="search-section">
                      <h3><FaHistory /> Recent Searches</h3>
                      {recentSearches.map(search => (
                        <div key={search.id} className="search-item" onClick={() => setSearchTerm(search.username)}>
                          {search.username}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="search-section">
                    <h3>Top Users</h3>
                    {console.log('Rendering top users:', topUsers)}
                    {topUsers.map(user => (
                      <div key={user.id} className="search-item" onClick={() => handleUserClick(user.id)}>
                        <img src={user.signedUrl || 'path/to/default/avatar.png'} alt={user.username} className="user-avatar" />
                        <span>{user.username}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {searchTerm && searchResults.length > 0 && (
                <div className="search-section">
                  <h3>Search Results</h3>
                  {searchResults.map(user => (
                    <div key={user.id} className="search-item" onClick={() => handleUserClick(user.id)}>
                      <img src={user.profile_picture_url || 'path/to/default/avatar.png'} alt={user.username} className="user-avatar" />
                      <span>{user.username}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'discover' ? 'active' : ''}`}
            onClick={() => setActiveTab('discover')}
          >
            Discover
          </button>
          <button 
            className={`tab ${activeTab === 'following' ? 'active' : ''}`}
            onClick={() => setActiveTab('following')}
          >
            Following
          </button>
        </div>
        {renderPosts()}
      </div>
      {fullScreenPostIndex !== null && (
        <FullScreenPost
          posts={posts}
          initialPostIndex={fullScreenPostIndex}
          onClose={handleFullScreenClose}
          onLike={handleLike}
          userId={userId}
          onCommentCountChange={handleCommentCountChange}
          initialShowComments={showComments}
          onIndexChange={handleFullScreenIndexChange}
        />
      )}
    </div>
  );
};

export default DiscoverPage;
