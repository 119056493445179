import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import ProductListPage from './ProductListPage';
import './CartPage.scss';

const CartPage = () => {
    const { userId } = useAuth();
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const base = process.env.REACT_APP_API_BASE_URL;
    const local = process.env.REACT_APP_LOCAL_URL;


    useEffect(() => {
        const fetchCartItems = async () => {
            if (!userId) return;
            try {
                const response = await fetch(`${base}/api/cart/getItems?userId=${userId}`);
                if (!response.ok) throw new Error('Failed to fetch cart items');
                const data = await response.json();
                setCartItems(data);
            } catch (error) {
                console.error('Error fetching cart items:', error);
            } finally {
                setIsLoading(false); // Set loading to false after fetching
            }
        };

        fetchCartItems();
    }, [userId]);

    const handleRemoveItem = async (productId) => {
        try {
            const response = await fetch(`${base}/api/cart/removeItem`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, productId }),
            });

            if (!response.ok) throw new Error('Failed to remove item from cart');

            // Update cartItems state correctly
            setCartItems(prevItems => prevItems.filter(item => item.product_id !== productId));
        } catch (error) {
            console.error('Error removing item from cart:', error);
        }
    };

    const handleCheckout = () => {
        // Implement checkout logic here
        console.log('Checkout clicked');
    };

    return (
        <div className="cart-page">
            <div className="sticky-header-container">
                <h1 className="page-title">Cart</h1>
            </div>
            {isLoading ? (
                <p>Loading your cart...</p>
            ) : cartItems.length === 0 ? (
                <p>Your cart is empty.</p>
            ) : (
                <ProductListPage 
                    products={cartItems} 
                    isCartPage={true}
                    onRemoveFromCart={handleRemoveItem}
                />
            )}
            <button className="cart-checkout-button" onClick={handleCheckout}>
                Checkout
            </button>
        </div>
    );
};

export default CartPage;
