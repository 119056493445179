import React, { useState } from 'react';
import './SearchBar.scss';
import { useNavigate } from 'react-router-dom';


const SearchBar = ({ initialQuery = '' }) => {
    const [query, setQuery] = useState(initialQuery);
    const navigate = useNavigate();
    const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const base = process.env.REACT_APP_API_BASE_URL;
  const local = process.env.REACT_APP_LOCAL_URL;

  const handleSearch = async () => {
    try {
        const response = await fetch(`${base}/api/search/query`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        });

        const data = await response.json();
        const resultsProducts = data.results; // Assuming 'results' is the key for search results in the API response

        
        console.log(resultsProducts);
        navigate('/results', { state: { query, resultsProducts } });
    } catch (error) {
        console.error('Error:', error);
    }
};


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
        handleSearch();
        }
    };

    return(
        <div className="search-box2-wrapper">
            <input
                type="text"
                className="search-box2"
                placeholder="Search for products"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            <div className="arrow-icon2" onClick={handleSearch}>
                <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    d="M12 2L10.59 3.41L18.17 11H2V13H18.17L10.59 20.59L12 22L22 12L12 2Z"
                    fill="currentColor"
                />
                </svg>
            </div>
        </div> 
    );
};

export default SearchBar;