import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './LandingPage';
import ResultsPage from './ResultsPage';
import LikesPage from './LikesPage';
import ProfilePage from './ProfilePage';
import CartPage from './CartPage';
import LoginPage from './Login';
import DiscoverPage from './DiscoverPage'; // Import the new DiscoverPage component
import './App.css';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './AuthProvider';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/likes" element={<PrivateRoute component={LikesPage} />} />
          <Route path="/profile/:userId" element={<PrivateRoute component={ProfilePage} />} /> {/* User-specific profile */}
          <Route path="/profile" element={<PrivateRoute component={ProfilePage} />} /> {/* General profile route */}
          <Route path="/cart" element={<PrivateRoute component={CartPage} />} />
          <Route path="/discover" element={<DiscoverPage />} /> {/* New Discover route */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
